export class ApiTokenResponse {
  readonly api_token: string;
  readonly expires_at: string;
  readonly is_impersonated: boolean;

  constructor(data: any) {
    this.api_token = data.api_token;
    this.expires_at = data.expires_at;
    this.is_impersonated = data.is_impersonated;
  }
}

export class UserExistsResponse {
  readonly exists: boolean;

  constructor(data: any) {
    this.exists = data.exists;
  }
}
