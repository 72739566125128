



































import Vue, { PropType } from "vue";
import { DataTableFilterInterface } from "@/components/data-table/DataTableFilter.vue";
import moment from "moment";
import _ from "lodash";

export default Vue.extend({
  name: "DataTableDateRangeFilter",
  props: {
    filter: {
      type: Object as PropType<DataTableFilterInterface>,
      required: true,
    },
  },
  data: () => ({
    // filter value
    value: [] as string[],
    // date picker dialog
    dialog: false,
  }),
  watch: {
    value(): void {
      this.onChange();
    },
  },
  computed: {
    valueFormatted(): string {
      if (!this.value || this.value.length === 0) {
        return "";
      }
      if (this.value.length === 1) {
        return moment(this.value[0]).format("L");
      }
      if (this.value.length === 2) {
        return [
          moment(this.value[0]).format("L"),
          moment(this.value[1]).format("L"),
        ].join(" – ");
      }
      return "";
    },
  },
  methods: {
    onChange(): void {
      if (!this.value || this.value.length === 0) {
        this.$emit("onChange", null);
        return;
      }
      let keys = _.isArray(this.filter.key) ? this.filter.key : [this.filter.key];
      const fromIsoDate = moment(this.value[0]).format();
      const toIsoDate = this.value.length === 1
        ? moment(this.value[0]).add(1, "day").format()
        : moment(this.value[1]).add(1, "day").format();
      let filterParts = keys.map((key: string): string => {
        const fromFilter = "gte:" + key + ":\"" + _.toString(fromIsoDate).replaceAll("\"", "\\\"") + "\"";
        const toFilter = "lte:" + key + ":\"" + _.toString(toIsoDate).replaceAll("\"", "\\\"") + "\"";
        return fromFilter + ";" + toFilter;
      });
      let filter = filterParts.join(";");
      if (filterParts.length > 1) filter = "or:(" + filter + ")";
      this.$emit("onChange", filter);
    },
  },
});
