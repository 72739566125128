


































































































































import { FilterQueryParameters } from "@/api/AbstractClient";
import ConversationClient from "@/api/chat/ConversationClient";
import { ConversationParticipationResponse, ConversationResponse } from "@/api/chat/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";
import CreateConversationForm from "@/components/chat/form/CreateConversationForm.vue";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "ConversationsList",
  components: { CreateConversationForm },
  props: {
    width: {
      type: Number,
      default: () => undefined,
    },
    perPage: {
      type: Number,
      default: () => 5,
    },
    selectedConversationId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyConversations: false,
    isBusyMarkAllAsRead: false,
    isBusyMarkAsRead: false,
    isBusyMarkAsUnread: false,
    // dialogs
    createConversationDialog: false,
    // data
    conversations: [] as ConversationResponse[],
    // pagination
    page: 1,
    // all conversations fetched
    allFetched: false,
    // selected conversation ID
    selectedConversation: null as number | null,
  }),
  methods: {
    isRead(conversation: ConversationResponse): boolean {
      const myParticipation = conversation.getMyParticipation(this.$store.getters["User/getId"]);
      if (!myParticipation || !conversation.last_message) return false;
      return myParticipation.read_at !== null ? myParticipation.read_at.isSameOrAfter(conversation.last_message.created_at) : false;
    },
    participants(conversation: ConversationResponse): UserResponse[] {
      return conversation.participations
        .filter((participation: ConversationParticipationResponse): boolean => participation.participant.id !== this.$store.getters["User/getId"])
        .map((participation: ConversationParticipationResponse): UserResponse => participation.participant);
    },
    loadMoreNotifications(): void {
      if (!this.allFetched) {
        this.page += 1;
        this.fetchConversations();
      }
    },
    onConversationClick(conversation: ConversationResponse): void {
      this.$router.push({ name: "conversation", params: { conversationId: _.toString(conversation.id) } })
        .catch(() => {
          // navigation duplicated
        });
      this.$emit("onConversationClick");
    },
    onMarkAsRead(conversation: ConversationResponse): void {
      this.isBusyMarkAsRead = true;
      ConversationClient.markConversationAsRead(conversation.id)
        .then(() => {
          const myParticipation = conversation.getMyParticipation(this.$store.getters["User/getId"]);
          if (myParticipation) myParticipation.read_at = moment();
          this.$emit("onMarkAsRead");
        })
        .finally(() => {
          this.isBusyMarkAsRead = false;
        });
    },
    onMarkAsUnread(conversation: ConversationResponse): void {
      this.isBusyMarkAsUnread = true;
      ConversationClient.markConversationAsUnread(conversation.id)
        .then(() => {
          const myParticipation = conversation.getMyParticipation(this.$store.getters["User/getId"]);
          if (myParticipation) myParticipation.read_at = null;
          this.$emit("onMarkAsUnread");
        })
        .finally(() => {
          this.isBusyMarkAsUnread = false;
        });
    },
    onMarkAllAsRead(): void {
      this.isBusyMarkAllAsRead = true;
      ConversationClient.markAllConversationsAsRead()
        .then(() => {
          this.conversations.forEach((conversation: ConversationResponse): void => {
            const myParticipation = conversation.getMyParticipation(this.$store.getters["User/getId"]);
            if (myParticipation) myParticipation.read_at = moment();
            this.$emit("onMarkAllAsRead");
          });
        })
        .finally(() => {
          this.isBusyMarkAllAsRead = false;
        });
    },
    onCreateConversation(): void {
      this.createConversationDialog = true;
    },
    fetchConversations(): void {
      const pagination: FilterQueryParameters = {
        page: this.page,
        itemsPerPage: this.perPage,
        filter: "",
        sort: "last_message.created_at:desc",
      };

      this.isBusyConversations = true;
      ConversationClient.getConversations(pagination)
        .then((response) => {
          this.conversations = this.conversations.concat(response.items);
          this.allFetched = response._pagination.total <= response._pagination.page * response._pagination.items_per_page;
        })
        .finally(() => {
          this.isBusyConversations = false;
        });
    },
  },
  created(): void {
    this.selectedConversation = this.selectedConversationId
      ? this.selectedConversationId : null;
    this.fetchConversations();
  },
});
