import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { UpdateFamilyRequest } from "@/api/family/types/Requests";
import { FamilyResponse } from "@/api/family/types/Responses";

export default class FamilyClient extends AbstractClient {
  /**
   * Perform get families request
   * @param pagination pagination/filters/sort configuration
   */
  static getFamilies(pagination: FilterQueryParameters): Promise<PaginatedResponse<FamilyResponse>> {
    return AbstractClient.get("/api/families", pagination)
      .then((response) => PaginatedResponse.fromResponseData(FamilyResponse, response.data));
  }

  /**
   * Perform get families by school class request
   * @param schoolClassId school class ID
   * @param pagination pagination/filters/sort configuration
   */
  static getFamiliesBySchoolClass(schoolClassId: number, pagination: FilterQueryParameters): Promise<PaginatedResponse<FamilyResponse>> {
    return AbstractClient.get("/api/families/by-school-class/" + schoolClassId, pagination)
      .then((response) => PaginatedResponse.fromResponseData(FamilyResponse, response.data));
  }

  /**
   * Perform get my family request
   */
  static getMyFamily(): Promise<FamilyResponse> {
    return AbstractClient.get("/api/families/my")
      .then((response) => new FamilyResponse(response.data));
  }

  /**
   * Perform get family request
   * @param familyId family ID
   */
  static getFamily(familyId: number): Promise<FamilyResponse> {
    return AbstractClient.get("/api/families/" + familyId)
      .then((response) => new FamilyResponse(response.data));
  }

  /**
   * Perform update family request
   * @param familyId family ID
   * @param request update family request
   */
  static updateFamily(familyId: number, request: UpdateFamilyRequest) {
    return AbstractClient.patch("/api/families/" + familyId, request)
      .then((response) => new FamilyResponse(response.data));
  }
}
