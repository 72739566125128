import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { CreateSchoolYearRequest, UpdateSchoolYearRequest } from "@/api/school-year/types/Requests";
import { AxiosResponse } from "axios";

export default class SchoolYearClient extends AbstractClient {
  /**
   * Perform get school years request
   * @param schoolId school ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getSchoolYears(schoolId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<SchoolYearResponse>> {
    return AbstractClient.get("/api/schools/" + schoolId + "/years", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(SchoolYearResponse, response.data));
  }

  /**
   * Preform get school year request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   */
  static getSchoolYear(schoolId: number, schoolYearId: number): Promise<SchoolYearResponse> {
    return AbstractClient.get("/api/schools/" + schoolId + "/years/" + schoolYearId)
      .then((response) => new SchoolYearResponse(response.data));
  }

  /**
   * Perform create school year request
   * @param schoolId school ID
   * @param request create school year request
   */
  static createSchoolYear(schoolId: number, request: CreateSchoolYearRequest): Promise<SchoolYearResponse> {
    return AbstractClient.post("/api/schools/" + schoolId + "/years", request)
      .then((response) => new SchoolYearResponse(response.data));
  }

  /**
   * Perform update school year request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param request update school year request
   */
  static updateSchoolYear(schoolId: number, schoolYearId: number, request: UpdateSchoolYearRequest): Promise<SchoolYearResponse> {
    return AbstractClient.patch("/api/schools/" + schoolId + "/years/" + schoolYearId, request)
      .then((response) => new SchoolYearResponse(response.data));
  }

  /**
   * Perform delete school year request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   */
  static deleteSchoolYear(schoolId: number, schoolYearId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/schools/" + schoolId + "/years/" + schoolYearId);
  }
}
