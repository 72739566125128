












import Vue, { PropType } from "vue";
import { DataTableFilterInterface, DataTableFilterOption } from "@/components/data-table/DataTableFilter.vue";
import _ from "lodash";

export default Vue.extend({
  name: "DataTableSelectFilter",
  props: {
    filter: {
      type: Object as PropType<DataTableFilterInterface>,
      required: true,
    },
  },
  data: () => ({
    // filter value
    value: null as string | number | boolean | null,
  }),
  watch: {
    value(): void {
      this.onChange();
    },
  },
  methods: {
    onChange(): void {
      if (this.value === null) {
        this.$emit("onChange", null);
        return;
      }
      let keys = _.isArray(this.filter.key) ? this.filter.key : [this.filter.key];
      let filterParts = keys.map((key: string): string => "eq:" + key + ":\"" + _.toString(this.value).replaceAll("\"", "\\\"") + "\"");
      let filter = filterParts.join(";");
      if (filterParts.length > 1) filter = "or:(" + filter + ")";
      this.$emit("onChange", filter);
    },
  },
  created(): void {
    if (!this.filter.options) throw "Missing options for filter type \"select\"";
    this.filter.options.forEach((option: DataTableFilterOption) => {
      if (option.default === true) {
        this.value = option.value;
        return;
      }
    });
  },
});
