






































































import { UpdateMyProfileRequest } from "@/api/user/types/Requests";
import { UserDetailResponse } from "@/api/user/types/Responses";
import UserClient from "@/api/user/UserClient";
import _ from "lodash";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "EditUserGeneralInfoForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyUser: false,
    // user data
    user: null as UserDetailResponse | null,
    // form
    form: {
      first_name: "" as string,
      last_name: "" as string,
      email: null as string | null,
      phone: null as string | null,
    },
  }),
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      phone: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyUser;
    },
    firstNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.first_name?.$dirty) return errors;
      !this.$v.form.first_name.required && errors.push(this.$t("users.validation.first_name.required"));
      return errors;
    },
    lastNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.last_name?.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push(this.$t("users.validation.last_name.required"));
      return errors;
    },
    emailErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.email?.$dirty) return errors;
      !this.$v.form.email.required && errors.push(this.$t("users.validation.email.required"));
      !this.$v.form.email.email && errors.push(this.$t("users.validation.email.email"));
      return errors;
    },
    phoneErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: UpdateMyProfileRequest = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: _.toString(this.form.email),
        phone: this.form.phone,
      };

      this.isBusySubmit = true;
      UserClient.updateMyProfile(request)
        .then((response) => {
          this.$store.commit("User/setData", {
            id: response.id,
            firstName: response.first_name,
            lastName: response.last_name,
            email: response.email,
            roles: response.roles,
          });
          this.$snackbarSuccess(this.$t("users.setting.setting_updated"));
          this.$emit("onSubmit");
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    fetchUser(): void {
      this.isBusyUser = true;
      UserClient.getMyProfile()
        .then((response) => {
          this.user = response;
          this.initDefaults();
        })
        .finally(() => {
          this.isBusyUser = false;
        });
    },
    initDefaults(): void {
      if (this.user === null) {
        return;
      }
      this.form.first_name = this.user.first_name;
      this.form.last_name = this.user.last_name;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone;
    },
  },
  created(): void {
    this.fetchUser();
  },
});
