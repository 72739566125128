






































import Vue from "vue";
import { FamilyResponse } from "@/api/family/types/Responses";
import FamilyClient from "@/api/family/FamilyClient";
import { ChildResponse } from "@/api/child/types/Responses";
import SelectChildItem from "@/components/layout/SelectChildItem.vue";

export default Vue.extend({
  name: "SelectChild",
  components: { SelectChildItem },
  data: () => ({
    // loading
    isBusyFamily: false,
    // family data
    family: null as FamilyResponse | null,
    // tap target
    showTapTarget: false,
    tapTargetFocus: null as any
  }),
  computed: {
    children(): ChildResponse[] {
      return this.family !== null ? this.family.children : [];
    },
    currentChildFullName(): string {
      const child = this.$store.getters["Family/getSelectedChild"];
      return child !== null ? child.full_name : this.$t("layout.select_child.select_child");
    },
    currentChildColor(): string {
      const child = this.$store.getters["Family/getSelectedChild"];
      return child !== null ? child.color : "white";
    },
    isChildSelected(): boolean {
      const child = this.$store.getters["Family/getSelectedChild"];
      return child !== null;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    onClickMenu(): void {
      this.showTapTarget = false;
      if (this.family === null || this.isBusyFamily) {
        this.isBusyFamily = true;
        FamilyClient.getMyFamily()
          .then((response) => {
            this.family = response;
          })
          .finally(() => {
            this.isBusyFamily = false;
          });
      }
    },
  },
  mounted(): void {
    this.tapTargetFocus = (this.$refs["select-child-button"] as any).$el;
    if (!this.isChildSelected) {
      setTimeout(() => {
        if (this.isMobile) {
          this.$emit("onNavigationDrawerClose");
        }
        this.showTapTarget = true;
      }, 2000);
    }
  },
});
