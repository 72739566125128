import AbstractClient from "@/api/AbstractClient";
import { ChangeSubscriptionRequest } from "@/api/stripe/types/Requests";
import { StripeCheckoutSessionResponse } from "@/api/stripe/types/Responses";
import { AxiosResponse } from "axios";

export default class StripeSubscriptionClient extends AbstractClient {
  /**
   * Perform change Stripe subscription request
   * @param request change Stripe subscription request
   */
  static changeSubscription(request: ChangeSubscriptionRequest): Promise<StripeCheckoutSessionResponse | AxiosResponse> {
    return AbstractClient.put("/api/stripe/subscriptions", request)
      .then((response) => {
        return response.status === 204 ? response : new StripeCheckoutSessionResponse(response.data);
      });
  }

  /**
   * Perform cancel Stripe subscription request
   */
  static cancelSubscription(): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/stripe/subscriptions");
  }
}
