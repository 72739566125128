




















































































import DataTableExport from "@/components/data-table/DataTableExport.vue";
import Vue, { PropType } from "vue";
import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import _ from "lodash";
import DataTableFilter, { DataTableFilterInterface } from "@/components/data-table/DataTableFilter.vue";
import { TranslateResult } from "vue-i18n";
import { hashCode } from "@/utils/String";
import { AxiosResponse } from "axios";

export interface DataTableHeader {
  text: TranslateResult | string;
  value: string;
  align?: "start" | "center" | "end";
  sortable?: boolean;
  filter?: DataTableFilterInterface;
  export?: DataTableExportInterface;
}

export interface DataTableAction {
  name: string;
  icon: string;
  onClick: CallableFunction;
  color?: string;
  tooltip?: string | TranslateResult;
  refetch?: boolean;
  default?: boolean;
  disabled?: CallableFunction;
  show?: CallableFunction;
  confirm?: CallableFunction;
  confirmText?: string | TranslateResult;
}

export interface DataTableExportInterface {
  name?: string | TranslateResult;
  json_paths: string[];
  delimiter?: string;
}

export default Vue.extend({
  name: "DataTable",
  components: { DataTableExport, DataTableFilter },
  props: {
    client: {
      type: AbstractClient,
      required: true,
    },
    clientFunction: {
      type: Function,
      required: true,
    },
    clientFunctionParameters: {
      type: Array,
      required: false,
    },
    clientQueryParameters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
    actions: {
      type: Array as PropType<DataTableAction[]>,
      required: false,
      default: () => [],
    },
    baseFilter: {
      type: String,
      required: false,
      default: () => "",
    },
    itemClass: {
      type: [String, Function],
      required: false,
      default: () => "",
    },
  },
  data: () => ({
    // loading
    isBusyData: 0,
    isBusyActions: {},
    // table data
    items: [] as any[],
    // external pagination and sorting properties
    total: 0,
    options: {},
    // table filters
    filters: {},
    // dialogs
    exportDialog: false,
  }),
  watch: {
    options: {
      handler() {
        this.fetchDataWithDebounce();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchDataWithDebounce();
      },
      deep: true,
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyData > 0;
    },
    showActions(): boolean {
      return this.filteredActions.length > 0;
    },
    headersWithActions(): DataTableHeader[] {
      if (!this.showActions) {
        return this.headers;
      }
      const headers = Array.from(this.headers);
      headers.push({
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      });
      return headers;
    },
    filteredActions(): DataTableAction[] {
      return _.isArray(this.actions) ? this.actions.filter((action: DataTableAction): boolean => action.show !== undefined ? action.show() : true) : [];
    },
    exportableColumns(): DataTableHeader[] {
      return this.headers.filter((header: DataTableHeader) => header.export !== undefined);
    },
    exportPath(): string {
      try {
        this.clientFunction.call(this.client, ...this.prepareClientFunctionParameters(true));
      } catch (url) {
        return url;
      }
      throw "Can not get URL path for export";
    },
  },
  methods: {
    showFilters(this: any): boolean {
      return this.headers.reduce((result: boolean, header: DataTableHeader) => result || this.showFilter(header), false);
    },
    showFilter(header: DataTableHeader): boolean {
      return header?.filter !== undefined;
    },
    isBusyAction(this: any, action: DataTableAction, item: any): boolean {
      return this.isBusyActions[this.getActionIdentifier(action, item.id)];
    },
    fetchDataWithDebounce: _.debounce(function(this: any) {
      this.fetchData();
    }, 250),
    fetchData(this: any, loading = true): Promise<AxiosResponse> {
      if (loading) this.isBusyData += 1;
      return this.clientFunction.call(this.client, ...this.prepareClientFunctionParameters())
        .then((response: PaginatedResponse<any>) => {
          this.items = response.items;
          this.total = response._pagination.total;
        })
        .finally(() => {
          if (loading) this.isBusyData -= 1;
        });
    },
    prepareClientFunctionParameters(throwPath = false): any[] {
      let parameters: any[] = [];
      if (this.clientFunctionParameters) {
        parameters = parameters.concat(this.clientFunctionParameters);
      }
      parameters = parameters.concat(this.prepareQueryParameters());
      if (throwPath) {
        parameters = parameters.concat({ throwPath: true });
      }
      return parameters;
    },
    prepareQueryParameters(this: any): FilterQueryParameters {
      const queryParameters = {
        page: this.preparePageQueryParameter(),
        itemsPerPage: this.prepareItemsPerPageQueryParameter(),
        filter: this.prepareFilterQueryParameters(),
        sort: this.prepareSortQueryParameters(),
      };
      if (!_.isEmpty(this.clientQueryParameters)) {
        _.merge(queryParameters, this.clientQueryParameters);
      }
      return queryParameters;
    },
    preparePageQueryParameter(this: any): number {
      return this.options.page ? this.options.page : 1;
    },
    prepareItemsPerPageQueryParameter(this: any): number {
      return this.options.itemsPerPage ? this.options.itemsPerPage : 10;
    },
    prepareFilterQueryParameters(this: any): string {
      return Object.keys(this.filters).length > 0 ? Object.values(this.filters).join(";") : "";
    },
    prepareSortQueryParameters(this: any): string {
      const sorts = [];
      if (_.isArray(this.options.sortBy)) {
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const header = this.headers.find(
            (header: DataTableHeader) => header.value === this.options.sortBy[i],
          );
          if (!header) continue;
          const key = header?.filter?.key;
          if (!key) continue;
          sorts.push(key + ":" + (this.options.sortDesc[i] ? "desc" : "asc"));
        }
      }
      return sorts.join(",");
    },
    onFilterChange(key: string, filter: string | null): void {
      if (filter === null) {
        this.$delete(this.filters, key);
      } else {
        this.$set(this.filters, key, filter);
      }
    },
    performAction(action: DataTableAction, item: any): void {
      if (this.disabled(action, item)) {
        return;
      }
      if (action.confirm && action.confirmText && action.confirm(item)) {
        if (!confirm(_.toString(action.confirmText))) {
          return;
        }
      }
      const identifier = this.getActionIdentifier(action, item.id);
      this.$set(this.isBusyActions, identifier, true);
      Promise.resolve(action.onClick(item))
        .finally(() => {
          if (action.refetch === true) {
            this.fetchData(false)
              .finally(() => {
                this.$set(this.isBusyActions, identifier, false);
              });
          } else {
            this.$set(this.isBusyActions, identifier, false);
          }
        });
    },
    hasDefaultAction(): boolean {
      return this.getDefaultAction() !== undefined;
    },
    getDefaultAction(): DataTableAction | undefined {
      return this.filteredActions.find((action: DataTableAction) => {
        return action.default === true;
      });
    },
    performDefaultAction(item: any): void {
      const action = this.getDefaultAction();
      if (action) {
        this.performAction(action, item);
      }
    },
    getActionIdentifier(action: DataTableAction, id: string | number): number {
      return hashCode(action.name + "-" + id);
    },
    refresh(): void {
      this.fetchData(false);
    },
    onClickItem(item: any): void {
      this.performDefaultAction(item);
    },
    initBaseFilter(): void {
      if (!_.isEmpty(this.baseFilter)) {
        this.$set(this.filters, "_base", this.baseFilter);
      }
    },
    disabled(action: DataTableAction, item: any): boolean {
      if (!action.disabled) {
        return false;
      }
      return action.disabled(item);
    },
  },
  created(): void {
    this.initBaseFilter();
    this.fetchData();
  },
});
