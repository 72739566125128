import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { CreateSchoolClassRequest, UpdateSchoolClassRequest } from "@/api/school-class/types/Requests";
import { AxiosResponse } from "axios";

export default class SchoolClassClient extends AbstractClient {
  /**
   * Perform get school classes request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getSchoolClasses(schoolId: number, schoolYearId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<SchoolClassResponse>> {
    return AbstractClient.get("/api/schools/" + schoolId + "/years/" + schoolYearId + "/classes", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(SchoolClassResponse, response.data));
  }

  /**
   * Perform get my school classes request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getMySchoolClasses(schoolId: number, schoolYearId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<SchoolClassResponse>> {
    return AbstractClient.get("/api/schools/" + schoolId + "/years/" + schoolYearId + "/classes/my", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(SchoolClassResponse, response.data));
  }

  /**
   * Perform get school class request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param schoolClassId school class ID
   */
  static getSchoolClass(schoolId: number, schoolYearId: number, schoolClassId: number): Promise<SchoolClassResponse> {
    return AbstractClient.get("/api/schools/" + schoolId + "/years/" + schoolYearId + "/classes/" + schoolClassId)
      .then((response) => new SchoolClassResponse(response.data));
  }

  /**
   * Perform create school class request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param request create school class request
   */
  static createSchoolClass(schoolId: number, schoolYearId: number, request: CreateSchoolClassRequest): Promise<SchoolClassResponse> {
    return AbstractClient.post("/api/schools/" + schoolId + "/years/" + schoolYearId + "/classes", request)
      .then((response) => new SchoolClassResponse(response.data));
  }

  /**
   * Perform update school class request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param schoolClassId school class ID
   * @param request update school class request
   */
  static updateSchoolClass(schoolId: number, schoolYearId: number, schoolClassId: number, request: UpdateSchoolClassRequest): Promise<SchoolClassResponse> {
    return AbstractClient.patch("/api/schools/" + schoolId + "/years/" + schoolYearId + "/classes/" + schoolClassId, request)
      .then((response) => new SchoolClassResponse(response.data));
  }

  /**
   * Perform delete school class request
   * @param schoolId school ID
   * @param schoolYearId school year ID
   * @param schoolClassId school class ID
   */
  static deleteSchoolClass(schoolId: number, schoolYearId: number, schoolClassId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/schools/" + schoolId + "/years/" + schoolYearId + "/classes/" + schoolClassId);
  }
}
