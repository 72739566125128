





























































































































import Vue from "vue";
import moment from "moment";
import { AbstractNotificationResponse } from "@/api/notification/types/Responses";
import NotificationClient from "@/api/notification/NotificationClient";
import { FilterQueryParameters } from "@/api/AbstractClient";

export default Vue.extend({
  name: "NotificationsList",
  data: () => ({
    // loading
    isBusyNotifications: false,
    isBusyMarkAllAsRead: false,
    isBusyMarkAsRead: false,
    isBusyMarkAsUnread: false,
    isBusyDelete: false,
    // data
    notifications: [] as AbstractNotificationResponse[],
    // pagination
    page: 1,
    perPage: 5,
    // all notifications fetched
    allFetched: false,
  }),
  methods: {
    isUnread(notification: AbstractNotificationResponse): boolean {
      return !notification.is_read;
    },
    createdAt(notification: AbstractNotificationResponse): string {
      const now = moment();
      const createdAt = notification.created_at;

      if (now.diff(createdAt, "seconds") < 60) {
        return this.$t("layout.notifications.now").toString();
      }

      const minutesDiff = now.diff(createdAt, "minutes");
      if (minutesDiff < 60) {
        return this.$tc("layout.notifications.n_minutes_ago", minutesDiff, { n: minutesDiff });
      }

      const hoursDiff = now.diff(createdAt, "hours");
      if (hoursDiff < 24) {
        return this.$tc("layout.notifications.n_hours_ago", hoursDiff, { n: hoursDiff });
      }

      return createdAt.format("LLL");
    },
    loadMoreNotifications(): void {
      if (!this.allFetched) {
        this.page += 1;
        this.fetchNotifications();
      }
    },
    onNotificationClick(notification: AbstractNotificationResponse): void {
      NotificationClient.markNotificationAsRead(notification.id)
        .then(() => window.location.replace(notification.url));
    },
    onMarkAsRead(notification: AbstractNotificationResponse): void {
      this.isBusyMarkAsRead = true;
      NotificationClient.markNotificationAsRead(notification.id)
        .then(() => {
          notification.is_read = true;
          this.$emit("onMarkAsRead");
        })
        .finally(() => {
          this.isBusyMarkAsRead = false;
        });
    },
    onMarkAsUnread(notification: AbstractNotificationResponse): void {
      this.isBusyMarkAsUnread = true;
      NotificationClient.markNotificationAsUnread(notification.id)
        .then(() => {
          notification.is_read = false;
          this.$emit("onMarkAsUnread");
        })
        .finally(() => {
          this.isBusyMarkAsUnread = false;
        });
    },
    onMarkAllAsRead(): void {
      this.isBusyMarkAllAsRead = true;
      NotificationClient.markAllNotificationsAsRead()
        .then(() => {
          this.notifications.forEach((notification: AbstractNotificationResponse): void => {
            notification.is_read = true;
            this.$emit("onMarkAllAsRead");
          });
        })
        .finally(() => {
          this.isBusyMarkAllAsRead = false;
        });
    },
    onDelete(notification: AbstractNotificationResponse): void {
      this.isBusyDelete = true;
      NotificationClient.deleteNotification(notification.id)
        .then(() => {
          this.$delete(this.notifications, this.notifications.indexOf(notification));
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    fetchNotifications(): void {
      const pagination: FilterQueryParameters = {
        page: this.page,
        itemsPerPage: this.perPage,
        filter: "",
        sort: "created_at:desc",
      };

      this.isBusyNotifications = true;
      NotificationClient.getNotifications(pagination)
        .then((response) => {
          this.notifications = this.notifications.concat(response.items);
          this.allFetched = response._pagination.total <= response._pagination.page * response._pagination.items_per_page;
        })
        .finally(() => {
          this.isBusyNotifications = false;
        });
    },
  },
  created(): void {
    this.fetchNotifications();
  },
});
