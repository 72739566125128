var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAutoTheme)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.onThemeChange}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lightbulb-auto")])],1)]}}],null,false,1861223827)},[_vm._v(" "+_vm._s(_vm.$t("theme.to_light_theme"))+" ")]):(_vm.isLightTheme)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.onThemeChange}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lightbulb")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("theme.to_dark_theme"))+" ")]):(_vm.isDarkTheme)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.onThemeChange}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lightbulb-off")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("theme.to_auto_theme"))+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }