























import Vue, { PropType } from "vue";
import DataTableTextFilter from "@/components/data-table/filters/DataTableTextFilter.vue";
import DataTableDateRangeFilter from "@/components/data-table/filters/DataTableDateRangeFilter.vue";
import DataTableBooleanFilter from "@/components/data-table/filters/DataTableBooleanFilter.vue";
import DataTableSelectFilter from "@/components/data-table/filters/DataTableSelectFilter.vue";
import { TranslateResult } from "vue-i18n";

export enum DataTableFilterType {
  TEXT,
  DATE_RANGE,
  BOOLEAN,
  SELECT,
}

export interface DataTableFilterOption {
  value: string | number | boolean | null;
  text: TranslateResult | string;
  default?: boolean;
}

export interface DataTableFilterInterface {
  key: string | string[];
  type: DataTableFilterType;
  handler?: (key: string, value: any) => string;
  // options for "select" filter type
  options?: DataTableFilterOption[];
  // labels for "boolean" filter type
  textAll?: TranslateResult | string;
  textYes?: TranslateResult | string;
  textNo?: TranslateResult | string;
}

export default Vue.extend({
  name: "DataTableFilter",
  components: { DataTableSelectFilter, DataTableBooleanFilter, DataTableDateRangeFilter, DataTableTextFilter },
  props: {
    filter: {
      type: Object as PropType<DataTableFilterInterface>,
      required: true,
    },
  },
  computed: {
    isTextFilter(): boolean {
      return this.filter.type === DataTableFilterType.TEXT;
    },
    isDateRangeFilter(): boolean {
      return this.filter.type === DataTableFilterType.DATE_RANGE;
    },
    isBooleanFilter(): boolean {
      return this.filter.type === DataTableFilterType.BOOLEAN;
    },
    isSelectFilter(): boolean {
      return this.filter.type === DataTableFilterType.SELECT;
    },
  },
  methods: {
    onChange(filter?: string): void {
      this.$emit("onChange", this.filter.key, filter);
    },
  },
});
