import AbstractClient from "@/api/AbstractClient";
import { ExportRequest } from "@/api/export/types/Requests";
import { AxiosResponse } from "axios";

export default class ExportClient extends AbstractClient {
  /**
   * Perform export request
   * @param request export request
   * @param contentType export file content type
   */
  static export(request: ExportRequest, contentType: string): Promise<AxiosResponse> {
    return AbstractClient.post("/api/exports", request, {}, { headers: { accept: contentType } });
  }
}
