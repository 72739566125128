





















































import Vue, { PropType } from "vue";
import StripeSubscriptionClient from "@/api/stripe/StripeSubscriptionClient";
import { ChangeSubscriptionRequest } from "@/api/stripe/types/Requests";
import {
  StripeCheckoutSessionResponse,
  StripePriceResponse,
  StripeProductResponse,
} from "@/api/stripe/types/Responses";

export default Vue.extend({
  name: "SubscriptionPlanCard",
  props: {
    stripeProduct: {
      type: Object as PropType<StripeProductResponse>,
      required: true,
    },
    stripePrice: {
      type: Object as PropType<StripePriceResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyCancel: false,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyCancel;
    },
    amountFormatted(): string {
      const formatter = new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: this.stripePrice.currency,
      });
      if (this.stripePrice.interval !== null) {
        return formatter.format(this.stripePrice.amount / 100) + " / " + this.$t("subscriptions.recurring_interval." + this.stripePrice.interval);
      } else {
        return formatter.format(this.stripePrice.amount / 100);
      }
    },
  },
  methods: {
    onChoosePlan(): void {
      if (!confirm(this.$t("subscriptions.confirm_submit", { subscription: this.stripeProduct.name }).toString())) {
        return;
      }

      const request: ChangeSubscriptionRequest = {
        stripe_price: this.stripePrice.stripe_price_id,
      };

      this.isBusySubmit = true;
      StripeSubscriptionClient.changeSubscription(request)
        .then((response) => {
          if (response instanceof StripeCheckoutSessionResponse) {
            window.location.replace(response.url);
          } else {
            window.location.replace(this.$router.resolve({ name: "subscriptions", query: { failure: "true" } }).href);
          }
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onCancelPlan(): void {
      if (!confirm(this.$t("subscriptions.confirm_cancel", { subscription: this.stripeProduct.name }).toString())) {
        return;
      }

      this.isBusyCancel = true;
      StripeSubscriptionClient.cancelSubscription()
        .then(() => {
          window.location.replace(this.$router.resolve({ name: "subscriptions", query: { cancel: "true" } }).href);
        })
        .finally(() => {
          this.isBusyCancel = false;
        });
    },
  },
});
