































































































import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { AllItems } from "@/api/AbstractClient";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { TranslateResult } from "vue-i18n";
import moment from "moment";

export default Vue.extend({
  name: "SchoolClassAndSchoolYearForm",
  mixins: [validationMixin],
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // loading
    isBusySchoolYears: false,
    isBusySchoolClasses: false,
    // form data
    form: {
      school_year: null as number | null,
      school_class: null as number | null,
    },
    // enums
    schoolYears: [] as SchoolYearResponse[],
    schoolClasses: [] as SchoolClassResponse[],
  }),
  validations: {
    form: {
      school_year: { required },
      school_class: { required },
    },
  },
  watch: {
    "form.school_year": function(schoolYearId?: number): void {
      if (schoolYearId) {
        this.fetchSchoolClasses(schoolYearId);
      } else {
        this.schoolClasses = [];
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySchoolYears || this.isBusySchoolClasses;
    },
    schoolYearErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.school_year?.$dirty) return errors;
      !this.$v.form.school_year.required && errors.push(this.$t("layout.validation.school_year.required"));
      return errors;
    },
    schoolClassErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.school_class?.$dirty) return errors;
      !this.$v.form.school_class.required && errors.push(this.$t("layout.validation.school_class.required"));
      return errors;
    },
  },
  methods: {
    initDefaults(): void {
      const childToSchoolClass = this.$store.getters["Family/getChildToSchoolClass"](this.child);
      if (childToSchoolClass) {
        this.form.school_year = childToSchoolClass.schoolClass.schoolYear.id;
        this.form.school_class = childToSchoolClass.schoolClass.id;
      }
    },
    fetchSchoolYears(): void {
      this.isBusySchoolYears = true;
      SchoolYearClient.getSchoolYears(this.child.school_id, new AllItems())
        .then((response) => {
          this.schoolYears = response.items;
        })
        .finally(() => {
          this.isBusySchoolYears = false;
        });
    },
    fetchSchoolClasses(schoolYearId: number): void {
      this.isBusySchoolClasses = true;
      SchoolClassClient.getSchoolClasses(this.child.school_id, schoolYearId, new AllItems())
        .then((response) => {
          this.schoolClasses = response.items.filter((schoolClass): boolean => this.child.school_class_ids.includes(schoolClass.id));
          if (this.form.school_class === null && this.schoolClasses.length === 1) {
            this.form.school_class = this.schoolClasses[0].id;
            this.onSubmit();
          }
        })
        .finally(() => {
          this.isBusySchoolClasses = false;
        });
    },
    isCurrentSchoolYear(schoolYear: SchoolYearResponse): boolean {
      return moment().isBetween(schoolYear.date_from, schoolYear.date_to, "days", "[]");
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const schoolClass = this.schoolClasses.find((schoolClass) => schoolClass.id === this.form.school_class);
      if (!schoolClass) return;

      this.$emit("onSubmit", schoolClass);
    },
  },
  created(): void {
    this.initDefaults();
    this.fetchSchoolYears();
  },
});
