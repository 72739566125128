











import Vue, { PropType } from "vue";
import { DataTableFilterInterface } from "@/components/data-table/DataTableFilter.vue";
import _ from "lodash";

export default Vue.extend({
  name: "DataTableTextFilter",
  props: {
    filter: {
      type: Object as PropType<DataTableFilterInterface>,
      required: true,
    },
  },
  data: () => ({
    // filter value
    value: null as string | null,
  }),
  watch: {
    value(): void {
      this.onChange();
    },
  },
  methods: {
    onChange(): void {
      if ([null, undefined, ""].includes(this.value)) {
        this.$emit("onChange", null);
        return;
      }
      let keys = _.isArray(this.filter.key) ? this.filter.key : [this.filter.key];
      let filterParts = keys.map((key: string): string => "like:" + key + ":\"" + _.toString(this.value).replaceAll("\"", "\\\"") + "\"");
      let filter = filterParts.join(";");
      if (filterParts.length > 1) filter = "or:(" + filter + ")";
      this.$emit("onChange", filter);
    },
  },
});
