




import Vue from "vue";
import { Route } from "vue-router";

export default Vue.extend({
  name: "Breadcrumbs",
  computed: {
    items() {
      const items = [];

      // start with current route
      let route: Route | null = this.$route;

      while (route) {
        // add route to breadcrumb array
        items.unshift({
          disabled: route.name === this.$route.name,
          text: this.$t("breadcrumb." + route.name),
          to: route,
          exact: true,
        });

        // process the parent route of current route if exists
        if (route?.meta?.parentRoute) {
          route = this.$router.resolve({
            name: route.meta.parentRoute,
            params: route.params,
          }).route;
        } else {
          route = null;
        }
      }

      return items;
    },
  },
});
