import _ from "lodash";

export class StripeProductResponse {
  readonly stripe_product_id: string;
  readonly name: string;
  readonly description: string | null;
  readonly features: string[];
  readonly scopes: string[];
  readonly restrictions: AbstractRestrictionResponse[];
  readonly active: boolean;
  readonly stripe_prices: StripePriceResponse[];

  constructor(data: any) {
    this.stripe_product_id = data.stripe_product_id;
    this.name = data.name;
    this.description = data.description;
    this.features = data.features;
    this.scopes = data.scopes;
    this.restrictions = _.toArray(data.restrictions).map((item: any) => new AbstractRestrictionResponse(item));
    this.active = data.active;
    this.stripe_prices = _.toArray(data.stripe_prices).map((item: any) => new StripePriceResponse(item));
  }
}

export class StripePriceResponse {
  readonly stripe_price_id: string;
  readonly amount: number;
  readonly currency: string;
  readonly interval: string | null;
  readonly active: boolean;
  readonly current: boolean;

  constructor(data: any) {
    this.stripe_price_id = data.stripe_price_id;
    this.amount = data.amount;
    this.currency = data.currency;
    this.interval = data.interval;
    this.active = data.active;
    this.current = data.current;
  }
}

export class StripeCheckoutSessionResponse {
  readonly id: string;
  readonly url: string;

  constructor(data: any) {
    this.id = data.id;
    this.url = data.url;
  }
}

export class AbstractRestrictionResponse {
  readonly id: string;
  readonly type: string;
  readonly name: string;

  constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
  }
}

export class ChildrenLimitRestrictionResponse extends AbstractRestrictionResponse {
  readonly limit: number | null;

  constructor(data: any) {
    super(data);
    this.limit = data.limit;
  }
}
