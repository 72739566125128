import AbstractClient from "@/api/AbstractClient";
import { CountryResponse } from "@/api/common/types/Responses";
import { AuthorizedPersonTypeResponse, RoleResponse } from "@/api/user/types/Responses";
import { ParentRelationshipTypeResponse } from "@/api/family/types/Responses";
import { NotificationTransportTypeResponse, NotificationTypeResponse } from "@/api/notification/types/Responses";

export default class EnumerationClient extends AbstractClient {
  /**
   * Perform get countries enum request
   */
  static getCountries(): Promise<CountryResponse[]> {
    return AbstractClient.get("/api/enum/countries", null, { cacheResponse: true })
      .then((response) => response.data.map((item: any) => new CountryResponse(item)));
  }

  /**
   * Perform get roles enum request
   */
  static getRoles(): Promise<RoleResponse[]> {
    return AbstractClient.get("/api/enum/roles", null, { cacheResponse: true })
      .then((response) => response.data.map((item: any) => new RoleResponse(item)));
  }

  /**
   * Perform get authorized person types enum request
   */
  static getAuthorizedPersonTypes(): Promise<AuthorizedPersonTypeResponse[]> {
    return AbstractClient.get("/api/enum/authorized-person-types", null, { cacheResponse: true })
      .then((response) => response.data.map((item: any) => new AuthorizedPersonTypeResponse(item)));
  }

  /**
   * Perform get parent relationship types enum request
   */
  static getParentRelationshipTypes(): Promise<ParentRelationshipTypeResponse[]> {
    return AbstractClient.get("/api/enum/parent-relationship-types", null, { cacheResponse: true })
      .then((response) => response.data.map((item: any) => new ParentRelationshipTypeResponse(item)));
  }

  /**
   * Perform get notification types request
   */
  static getNotificationTypes(): Promise<NotificationTypeResponse[]> {
    return AbstractClient.get("/api/enum/notification-types", null, { cacheResponse: true })
      .then((response) => response.data.map((item: any) => new NotificationTypeResponse(item)));
  }

  /**
   * Perform get notification transport types request
   */
  static getNotificationTransportTypes(): Promise<NotificationTransportTypeResponse[]> {
    return AbstractClient.get("/api/enum/notification-transport-types", null, { cacheResponse: true })
      .then((response) => response.data.map((item: any) => new NotificationTransportTypeResponse(item)));
  }
}
