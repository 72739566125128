import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { StripeProductResponse } from "@/api/stripe/types/Responses";

export default class StripeProductClient extends AbstractClient {
  /**
   * Perform get Stripe products request
   * @param pagination pagination/filters/sort configuration
   */
  static getStripeProducts(pagination: FilterQueryParameters): Promise<PaginatedResponse<StripeProductResponse>> {
    return AbstractClient.get("/api/stripe/products", pagination)
      .then((response) => PaginatedResponse.fromResponseData(StripeProductResponse, response.data));
  }
}
