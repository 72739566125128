





























import ConversationClient from "@/api/chat/ConversationClient";
import ConversationsList from "@/components/chat/misc/ConversationsList.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Conversations",
  components: { ConversationsList },
  data: () => ({
    // loading
    isBusyHasUnreadConversations: false,
    // menu
    conversationsMenu: false,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyHasUnreadConversations;
    },
    hasUnreadConversations(): boolean {
      return this.$store.getters["Application/hasUnreadConversations"];
    },
  },
  methods: {
    onMarkAsRead(): void {
      this.fetchHasUnreadConversations();
    },
    onMarkAsUnread(): void {
      this.fetchHasUnreadConversations();
    },
    onConversationClick(): void {
      this.conversationsMenu = false;
    },
    onMarkAllAsRead(): void {
      this.$store.commit("Application/setHasUnreadMessages", false);
    },
    fetchHasUnreadConversations(): void {
      this.isBusyHasUnreadConversations = true;
      ConversationClient.hasUnreadConversations()
        .then((response) => {
          this.$store.commit("Application/setHasUnreadMessages", response.has_unread_conversations);
        })
        .finally(() => {
          this.isBusyHasUnreadConversations = false;
        });
    },
  },
});
