






























import Vue from "vue";
import Theme from "@/enums/Theme";

export default Vue.extend({
  name: "ThemeSwitch",
  computed: {
    isAutoTheme(): boolean {
      return this.$store.getters["Theme/getTheme"] === Theme.AUTO;
    },
    isLightTheme(): boolean {
      return this.$store.getters["Theme/getTheme"] === Theme.LIGHT;
    },
    isDarkTheme(): boolean {
      return this.$store.getters["Theme/getTheme"] === Theme.DARK;
    },
  },
  methods: {
    onThemeChange(): void {
      if (this.isAutoTheme) {
        // auto -> light
        this.$store.commit("Theme/setTheme", Theme.LIGHT);
      } else if (this.isLightTheme) {
        // light -> dark
        this.$store.commit("Theme/setTheme", Theme.DARK);
      } else if (this.isDarkTheme) {
        // dark -> auto
        this.$store.commit("Theme/setTheme", Theme.AUTO);
      }
    },
  },
});
