












































import { AllItems } from "@/api/AbstractClient";
import StripeProductClient from "@/api/stripe/StripeProductClient";
import { StripePriceResponse, StripeProductResponse } from "@/api/stripe/types/Responses";
import SubscriptionPlanCard from "@/components/subscription/card/SubscriptionPlanCard.vue";
import RecurringInterval from "@/enums/RecurringInterval";
import SubscriptionScope from "@/enums/SubscriptionScope";
import Vue from "vue";

export default Vue.extend({
  name: "SubscriptionPlans",
  components: { SubscriptionPlanCard },
  props: {
    isBusyMyProfile: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // loading
    isBusyStripeProducts: false,
    // Stripe products
    stripeProducts: [] as StripeProductResponse[],
    // filter
    scope: SubscriptionScope.KINDERGARTEN as string,
    recurring: RecurringInterval.MONTH as string,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyStripeProducts || this.isBusyMyProfile;
    },
    subscriptionScopeOptions(): any[] {
      return [
        {
          text: this.$t("subscriptions.subscription_scopes.for_kindergarten"),
          value: SubscriptionScope.KINDERGARTEN,
        },
        {
          text: this.$t("subscriptions.subscription_scopes.for_elementary_school"),
          value: SubscriptionScope.ELEMENTARY_SCHOOL,
        },
      ];
    },
    recurringIntervalOptions(): any[] {
      return [
        {
          text: this.$t("subscriptions.recurring_interval.per_month"),
          value: RecurringInterval.MONTH,
        },
        {
          text: this.$t("subscriptions.recurring_interval.per_year"),
          value: RecurringInterval.YEAR,
        },
      ];
    },
  },
  methods: {
    fetchStripeProducts(): void {
      this.isBusyStripeProducts = true;
      const filter = "eq:is_active:\"true\"";
      StripeProductClient.getStripeProducts(new AllItems(filter))
        .then((response) => {
          this.stripeProducts = response.items;
        })
        .finally(() => {
          this.isBusyStripeProducts = false;
        });
    },
    filteredStripePrices(stripeProduct: StripeProductResponse): StripePriceResponse[] {
      return stripeProduct.stripe_prices.filter((stripePrice: StripePriceResponse): boolean => stripePrice.interval === this.recurring && stripeProduct.scopes.includes(this.scope));
    },
  },
  created(): void {
    this.fetchStripeProducts();
  },
});
