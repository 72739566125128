

















































import EditUserGeneralInfoForm from "@/components/users/form/EditUserGeneralInfoForm.vue";
import ReplaceAvatarForm from "@/components/users/form/ReplaceAvatarForm.vue";
import Vue from "vue";
import EditUserNotificationSettingForm from "@/components/users/form/EditUserNotificationSettingForm.vue";
import { SettingResponse } from "@/api/user/types/Responses";
import UserClient from "@/api/user/UserClient";

export default Vue.extend({
  name: "EditUserSettingForm",
  components: { ReplaceAvatarForm, EditUserGeneralInfoForm, EditUserNotificationSettingForm },
  data: () => ({
    // loading
    isBusySetting: false,
    // tabs
    tab: 0 as number,
    // setting
    setting: null as SettingResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySetting;
    },
  },
  methods: {
    fetchSetting(): void {
      this.isBusySetting = true;
      UserClient.getMyProfile()
        .then((response) => {
          this.setting = response.setting;
        })
        .finally(() => {
          this.isBusySetting = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
  created(): void {
    this.fetchSetting();
  },
});
