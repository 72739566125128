































































































































































import { ChildToSchoolClassSettingResponse } from "@/api/user/types/Responses";
import UserClient from "@/api/user/UserClient";
import Conversations from "@/components/layout/Conversations.vue";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import SubscriptionPlans from "@/components/subscription/SubscriptionPlans.vue";
import PollingService from "@/service/PollingService";
import Vue from "vue";
import Snackbar from "@/components/layout/Snackbar.vue";
import ThemeSwitch from "@/components/layout/ThemeSwitch.vue";
import Role from "@/enums/Role";
import Breadcrumbs from "@/components/layout/Breadcrumb.vue";
import SchoolAndSchoolYearSetting from "@/layouts/misc/SchoolAndSchoolYearSetting.vue";
import Notifications from "@/components/layout/Notifications.vue";
import SelectChild from "@/components/layout/SelectChild.vue";
import SelectSchoolClass from "@/components/layout/SelectSchoolClass.vue";
import EditUserSettingForm from "@/components/users/form/EditUserSettingForm.vue";

export interface MenuItem {
  title: string;
  icon: string;
  to: string;
  exact: boolean;
  badge?: MenuItemBadge;
}

export interface MenuItemBadge {
  value: string | number | null;
  color: string;
}

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    HelpMessage,
    SubscriptionPlans,
    Conversations,
    EditUserSettingForm,
    SelectSchoolClass,
    SelectChild,
    Notifications,
    SchoolAndSchoolYearSetting,
    Breadcrumbs,
    ThemeSwitch,
    Snackbar,
  },
  props: {
    fillHeight: {
      type: Boolean,
      default: () => false,
    },
    fluid: {
      type: Boolean,
      default: () => false,
    },
    hideBreadcrumb: {
      type: Boolean,
      default: () => false,
    },
    hideLeftDrawer: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // loading
    isBusyRevertIdentity: false,
    // drawer
    drawer: true,
    // dialogs
    settingDialog: false,
    // dialogs
    subscriptionPlansDialog: false,
    // speed dial
    fab: false,
  }),
  computed: {
    isImpersonated(): boolean {
      return this.$store.getters["ApiToken/getIsImpersonated"];
    },
    success(): boolean {
      return this.$route.query.success === "true";
    },
    fullName(): string {
      return this.$isAuthenticated()
        ? this.$store.getters["User/getFullName"]
        : "";
    },
    avatarUrl(): string {
      const avatarUrl = this.$store.getters["User/getAvatarUrl"];
      return avatarUrl !== null ? avatarUrl : "assets/avatar/avatar.svg";
    },
    roles(): string {
      return this.$store.getters["User/getRoles"]
        .filter((role: string): boolean => role !== Role.USER)
        .map((role: string): string => this.$t("enum.role." + role).toString())
        .join(", ");
    },
    menuItems(): MenuItem[] {
      return [
        {
          title: this.$t("dashboard.dashboard").toString(),
          icon: "mdi-gauge",
          to: "dashboard",
          exact: true,
        },
        {
          title: this.$t("users.users").toString(),
          icon: "mdi-account-multiple",
          to: "users",
          exact: true,
        },
        {
          title: this.$t("schools.schools").toString(),
          icon: "mdi-home-city",
          to: "schools",
          exact: true,
        },
        {
          title: this.$t("applications.applications").toString(),
          icon: "mdi-file-replace-outline",
          to: "applications",
          exact: true,
          badge: {
            color: "error",
            value: this.$store.getters["Application/getUnprocessedApplicationsCount"],
          },
        },
        {
          title: this.$t("school_classes.school_classes").toString(),
          icon: "mdi-google-classroom",
          to: "schoolClasses",
          exact: true,
        },
        {
          title: this.$t("timetables.timetable_records").toString(),
          icon: "mdi-timetable",
          to: "timetableRecords",
          exact: true,
        },
        {
          title: this.$t("family.family_members").toString(),
          icon: "mdi-account-multiple",
          to: "familyMembers",
          exact: true,
        },
        {
          title: this.$t("events.events").toString(),
          icon: "mdi-calendar-range",
          to: "events",
          exact: true,
        },
        {
          title: this.$t("subscriptions.subscriptions").toString(),
          icon: "mdi-ticket",
          to: "subscriptions",
          exact: true,
        },
      ];
    },
    filteredMenuItems(): MenuItem[] {
      return this.menuItems.filter((menuItem: MenuItem) => {
        const route = this.$router.resolve({ name: menuItem.to });
        const userRoles = this.$store.getters["User/getRoles"] as string[];
        const requiredRoles = route.route?.meta?.requiredRoles as Role[] | undefined;
        return !requiredRoles || requiredRoles.some((requiredRole: Role): boolean => userRoles.includes(requiredRole.valueOf()));
      });
    },
  },
  methods: {
    onLogout() {
      this.$store.commit("User/resetData");
      this.$store.commit("Stripe/resetData");
      this.$store.commit("ApiToken/resetData");
      this.$store.commit("Setting/resetData");
      this.$store.commit("Family/resetData");
      this.$snackbarSuccess(this.$t("theme.logout_successfully"));
      PollingService.getInstance().reset();
      this.$router.push({ name: "home" });
    },
    onRevertIdentity(): void {
      this.$store.commit("ApiToken/resetDataImpersonate");
      this.isBusyRevertIdentity = true;
      UserClient.getMyProfile()
        .then((myProfileResponse) => {
          this.$store.commit("User/setData", {
            id: myProfileResponse.id,
            firstName: myProfileResponse.first_name,
            lastName: myProfileResponse.last_name,
            email: myProfileResponse.email,
            roles: myProfileResponse.roles,
            avatarUrl: myProfileResponse.avatar_url,
          });
          // from my profile response (user data)
          this.$store.commit("Setting/setSelectedSchool", myProfileResponse.setting.selected_school);
          this.$store.commit("Setting/setSelectedSchoolYear", myProfileResponse.setting.selected_school_year);
          this.$store.commit("Setting/setSelectedSchoolClass", myProfileResponse.setting.selected_school_class);
          this.$store.commit("Family/setSelectedChild", { selectedChild: myProfileResponse.setting.selected_child });
          // from my profile response (founder data)
          if (myProfileResponse.founder) {
            this.$store.commit("Stripe/setStripeCustomerId", myProfileResponse.founder.stripe_customer_id);
            this.$store.commit("Stripe/setStripePriceId", myProfileResponse.founder.stripe_price_id);
            this.$store.commit("Stripe/setStripeSubscriptionId", myProfileResponse.founder.stripe_subscription_id);
          }
          myProfileResponse.setting.child_to_school_class_settings.forEach((childToSchoolClass: ChildToSchoolClassSettingResponse): void => {
            this.$store.commit("Family/setChildToSchoolClass", {
              child: childToSchoolClass.child,
              schoolClass: childToSchoolClass.school_class,
            });
          });
          // reset polling
          PollingService.getInstance().reset();
          // snackbar
          this.$snackbarSuccess(this.$t("layout.identity_reverted"));
          // go to user's dashboard
          this.$router.push({ name: "dashboard" })
            .catch(() => {
              // navigation duplicated
            });
        })
        .finally(() => {
          this.isBusyRevertIdentity = false;
        });
    },
    checkSubscriptionsDialog(): void {
      if (!this.success && this.$isFounder() && (this.$store.getters["Stripe/getStripePriceId"] === null || this.$store.getters["Stripe/getStripeSubscriptionId"] === null)) {
        this.subscriptionPlansDialog = true;
      }
    },
  },
  created(): void {
    this.checkSubscriptionsDialog();
    this.$store.watch(() => this.$store.getters["Stripe/getStripePriceId"], () => {
      this.checkSubscriptionsDialog();
    });
    this.$store.watch(() => this.$store.getters["Stripe/getStripeSubscriptionId"], () => {
      this.checkSubscriptionsDialog();
    });
  },
});
