












import Vue, { PropType } from "vue";
import { DataTableFilterInterface, DataTableFilterOption } from "@/components/data-table/DataTableFilter.vue";
import _ from "lodash";

export default Vue.extend({
  name: "DataTableBooleanFilter",
  props: {
    filter: {
      type: Object as PropType<DataTableFilterInterface>,
      required: true,
    },
  },
  data: () => ({
    // filter value
    value: null as boolean | null,
  }),
  watch: {
    value(): void {
      this.onChange();
    },
  },
  computed: {
    items(): DataTableFilterOption[] {
      return [
        {
          value: null,
          text: this.filter.textAll ?? this.$t("data_table.all"),
        },
        {
          value: true,
          text: this.filter.textYes ?? this.$t("data_table.yes"),
        },
        {
          value: false,
          text: this.filter.textNo ?? this.$t("data_table.no"),
        },
      ];
    },
  },
  methods: {
    onChange(): void {
      if (this.value === null) {
        this.$emit("onChange", null);
        return;
      }
      let keys = _.isArray(this.filter.key) ? this.filter.key : [this.filter.key];
      let filterParts = keys.map((key: string): string => {
        if (this.filter.handler) {
          return this.filter.handler(key, this.value);
        } else {
          return "eq:" + key + ":\"" + (this.value ? "true" : "false") + "\"";
        }
      });
      let filter = filterParts.join(";");
      if (filterParts.length > 1) filter = "or:(" + filter + ")";
      this.$emit("onChange", filter);
    },
  },
});
