











import Vue from "vue";

export default Vue.extend({
  name: "Snackbar",
  data: () => ({
    // snackbar data
    message: null,
    color: null,
    // snackbar visible flag
    visible: false,
    // snackbar timeout (in milliseconds)
    timeout: 6000,
  }),
  methods: {
    onClose() {
      this.visible = false;
      this.$store.commit("Snackbar/resetSnackbar");
    },
  },
  created(): void {
    // show stored snackbar
    if (this.$store.getters["Snackbar/isVisible"]) {
      this.visible = true;
      this.message = this.$store.getters["Snackbar/getMessage"];
      this.color = this.$store.getters["Snackbar/getColor"];
      setTimeout(() => {
        this.visible = false;
        this.$store.commit("Snackbar/resetSnackbar");
      }, this.timeout);
    }

    // watch for new snackbar
    this.$store.watch(() => this.$store.getters["Snackbar/isVisible"], (isVisible) => {
        if (isVisible) {
          this.visible = true;
          this.message = this.$store.getters["Snackbar/getMessage"];
          this.color = this.$store.getters["Snackbar/getColor"];
          setTimeout(() => {
            this.visible = false;
            this.$store.commit("Snackbar/resetSnackbar");
          }, this.timeout);
        }
      },
    );
  },
});
