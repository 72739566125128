


































import HelpMessage from "@/components/layout/HelpMessage.vue";
import Vue, { PropType } from "vue";
import EnumerationClient from "@/api/enum/EnumerationClient";
import { NotificationTransportTypeResponse, NotificationTypeResponse } from "@/api/notification/types/Responses";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";
import { validationMixin } from "vuelidate";
import { UpdateSettingRequest } from "@/api/user/types/Requests";
import UserClient from "@/api/user/UserClient";
import { SettingResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "EditUserNotificationSettingForm",
  mixins: [validationMixin],
  components: { HelpMessage, PageSubtitle },
  props: {
    setting: {
      type: Object as PropType<SettingResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyNotificationTransportTypes: false,
    isBusyNotificationTypes: false,
    // enums
    notificationTypes: [] as NotificationTypeResponse[],
    notificationTransportTypes: [] as NotificationTransportTypeResponse[],
    // form
    form: {
      notification_settings: [] as string[],
    },
  }),
  validations: {
    form: {
      notification_settings: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyNotificationTransportTypes || this.isBusyNotificationTypes;
    },
    filteredNotificationTypes(): NotificationTypeResponse[] {
      const roles = this.$store.getters["User/getRoles"];
      return this.notificationTypes.filter((notificationType: NotificationTypeResponse): boolean => notificationType.roles.filter((role: string): boolean => roles.includes(role)).length > 0);
    },
    filteredNotificationTransportTypes(): NotificationTransportTypeResponse[] {
      return this.notificationTransportTypes;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: UpdateSettingRequest = {
        notification_settings: this.form.notification_settings.map((notificationSetting: string) => ({
          notification_type: notificationSetting.split(";")[0],
          notification_transport_type: notificationSetting.split(";")[1],
        })),
      };

      this.isBusySubmit = true;
      UserClient.updateUserSetting(request)
        .then(() => {
          this.$snackbarSuccess(this.$t("users.setting.setting_updated"));
          this.$emit("onSubmit");
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    fetchNotificationTypes(): void {
      this.isBusyNotificationTypes = true;
      EnumerationClient.getNotificationTypes()
        .then((response) => {
          this.notificationTypes = response;
        })
        .finally(() => {
          this.isBusyNotificationTypes = false;
        });
    },
    fetchNotificationTransportTypes(): void {
      this.isBusyNotificationTransportTypes = true;
      EnumerationClient.getNotificationTransportTypes()
        .then((response) => {
          this.notificationTransportTypes = response;
        })
        .finally(() => {
          this.isBusyNotificationTransportTypes = false;
        });
    },
    initDefaults(): void {
      this.setting.notification_settings.forEach((notificationSetting) => {
        if (notificationSetting.is_allowed) {
          const checkboxValue = this.checkboxValue(notificationSetting.notification_type, notificationSetting.notification_transport_type);
          !this.form.notification_settings.push(checkboxValue);
        }
      });
    },
    checkboxValue(notificationType: string, notificationTransportType: string): string {
      return notificationType + ";" + notificationTransportType;
    },
  },
  created(): void {
    this.fetchNotificationTypes();
    this.fetchNotificationTransportTypes();
    this.initDefaults();
  },
});
