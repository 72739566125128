









































import Vue from "vue";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { AllItems } from "@/api/AbstractClient";
import UserClient from "@/api/user/UserClient";
import { UpdateSettingRequest } from "@/api/user/types/Requests";

export default Vue.extend({
  name: "SelectSchoolClass",
  data: () => ({
    // loading
    isBusySchoolClasses: false,
    isBusySetting: false,
    // school classes data
    schoolClasses: [] as SchoolClassResponse[],
    // tap target
    showTapTarget: false,
    tapTargetFocus: null as any,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySchoolClasses || this.isBusySetting;
    },
    currentSchoolClassName(): string {
      const schoolClass = this.$store.getters["Setting/getSelectedSchoolClass"];
      return schoolClass !== null ? schoolClass.name : this.$t("layout.select_school_class.select_school_class");
    },
    schoolId(): number | null {
      const school = this.$store.getters["Setting/getSelectedSchool"];
      return school !== null ? school.id : null;
    },
    schoolYearId(): number | null {
      const schoolYear = this.$store.getters["Setting/getSelectedSchoolYear"];
      return schoolYear !== null ? schoolYear.id : null;
    },
    isSchoolClassSelected(): boolean {
      const schoolClass = this.$store.getters["Setting/getSelectedSchoolClass"];
      return schoolClass !== null;
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    onClickMenu(): void {
      this.showTapTarget = false;
      if (this.schoolClasses.length === 0 || this.isBusySchoolClasses) {
        if (this.schoolId !== null && this.schoolYearId !== null) {
          this.isBusySchoolClasses = true;
          SchoolClassClient.getMySchoolClasses(this.schoolId, this.schoolYearId, new AllItems())
            .then((response) => {
              this.schoolClasses = response.items;
            })
            .finally(() => {
              this.isBusySchoolClasses = false;
            });
        }
      }
    },
    onClickSchoolClass(schoolClass: SchoolClassResponse): void {
      const request: UpdateSettingRequest = {
        selected_school_class: schoolClass.id,
      };

      this.isBusySetting = true;
      UserClient.updateUserSetting(request)
        .then((response) => {
          this.$store.commit("Setting/setSelectedSchoolClass", response.selected_school_class);
          window.location.reload();
        })
        .finally(() => {
          this.isBusySetting = false;
        });
    },
  },
  mounted(): void {
    this.tapTargetFocus = (this.$refs["select-school-class-button"] as any).$el;
    if (!this.isSchoolClassSelected) {
      setTimeout(() => {
        if (this.isMobile) {
          this.$emit("onNavigationDrawerClose");
        }
        this.showTapTarget = true;
      }, 2000);
    }
  },
});
