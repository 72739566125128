import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";

export default class ParticipantClient extends AbstractClient {
  /**
   * Perform get participants request
   * @param pagination pagination/filters/sort configuration
   */
  static getParticipants(pagination: FilterQueryParameters): Promise<PaginatedResponse<UserResponse>> {
    return AbstractClient.get("/api/chat/participants", pagination)
      .then((response) => PaginatedResponse.fromResponseData(UserResponse, response.data));
  }
}
