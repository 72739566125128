import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { PinCodeResponse, SettingResponse, UserDetailResponse, UserResponse } from "@/api/user/types/Responses";
import { UserExistsResponse } from "@/api/security/types/Responses";
import {
  CreateAuthorizedPersonRequest,
  CreateUserRequest,
  UpdateAuthorizedPersonRequest, UpdateMyProfileRequest,
  UpdateSettingRequest,
  UpdateUserRequest,
} from "@/api/user/types/Requests";
import { AxiosResponse } from "axios";

export default class UserClient extends AbstractClient {
  /**
   * Perform get users request
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getUsers(pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<UserResponse>> {
    return AbstractClient.get("/api/users", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(UserResponse, response.data));
  }

  /**
   * Perform get user request
   * @param userId user ID
   */
  static getUser(userId: number): Promise<UserDetailResponse> {
    return AbstractClient.get("/api/users/" + userId)
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform get my profile request
   * @param apiToken optional user's API token
   */
  static getMyProfile(apiToken?: string): Promise<UserDetailResponse> {
    return AbstractClient.get("/api/users/me", null, {}, apiToken ? { headers: { "X-Auth-Token": apiToken } } : {})
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform get user by email exists request
   * @param email user's e-mail
   */
  static getUserByEmailExists(email: string): Promise<UserExistsResponse> {
    return AbstractClient.get("/api/users/by-email/" + encodeURI(email) + "/exists")
      .then((response) => new UserExistsResponse(response.data));
  }

  /**
   * Perform create user request
   * @param request create user request
   */
  static createUser(request: CreateUserRequest): Promise<UserDetailResponse> {
    return AbstractClient.post("/api/users", request)
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform update user request
   * @param userId user ID
   * @param request update user request
   */
  static updateUser(userId: number, request: UpdateUserRequest): Promise<UserDetailResponse> {
    return AbstractClient.patch("/api/users/" + userId, request)
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform update user setting request
   * @param request update setting request
   */
  static updateUserSetting(request: UpdateSettingRequest): Promise<SettingResponse> {
    return AbstractClient.patch("/api/users/me/settings", request)
      .then((response) => new SettingResponse(response.data));
  }

  /**
   * Perform update my profile request
   * @param request update my profile request
   */
  static updateMyProfile(request: UpdateMyProfileRequest): Promise<UserDetailResponse> {
    return AbstractClient.patch("/api/users/me", request)
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform delete user request
   * @param userId user ID
   */
  static deleteUser(userId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/users/" + userId);
  }

  /**
   * Perform create authorized person request
   * @param request create authorized person request
   */
  static createAuthorizedPerson(request: CreateAuthorizedPersonRequest): Promise<UserDetailResponse> {
    return AbstractClient.post("/api/users/authorized-persons", request)
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform update authorized person request
   * @param userId user ID
   * @param request update authorized person request
   */
  static updateAuthorizedPerson(userId: number, request: UpdateAuthorizedPersonRequest): Promise<UserDetailResponse> {
    return AbstractClient.patch("/api/users/authorized-persons/" + userId, request)
      .then((response) => new UserDetailResponse(response.data));
  }

  /**
   * Perform get my PIN code request
   */
  static getMyPinCode(): Promise<PinCodeResponse> {
    return AbstractClient.get("/api/users/me/pin-code")
      .then((response) => new PinCodeResponse(response.data));
  }

  /**
   * Perform get user's PIN code request
   * @param userId user ID
   */
  static getUsersPinCode(userId: number): Promise<PinCodeResponse> {
    return AbstractClient.get("/api/users/" + userId + "/pin-code")
      .then((response) => new PinCodeResponse(response.data));
  }

  /**
   * Perform replace my avatar request
   * @param formData
   */
  static replaceMyAvatar(formData: FormData): Promise<AxiosResponse> {
    return AbstractClient.post("/api/users/me/avatar", formData);
  }

  /**
   * Regenerate authorized person's PIN code
   * @param userId user ID
   */
  static regenerateAuthorizedPersonPinCode(userId: number): Promise<PinCodeResponse> {
    return AbstractClient.post("/api/users/" + userId + "/regenerate-pin-code")
      .then((response) => new PinCodeResponse(response.data));
  }
}
