
















































































import { AllItems } from "@/api/AbstractClient";
import ConversationClient from "@/api/chat/ConversationClient";
import { CreateConversationRequest } from "@/api/chat/types/Requests";
import ParticipantClient from "@/api/child/ParticipantClient";
import { UserResponse } from "@/api/user/types/Responses";
import { instrumentOutgoingRequests } from "@sentry/tracing/dist/browser";
import _ from "lodash";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "CreateConversationForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusyParticipants: false,
    isBusySubmit: false,
    // data
    participants: [] as UserResponse[],
    // form data
    form: {
      participant: null,
      content: null,
    },
  }),
  validations: {
    form: {
      participant: { required },
      content: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyParticipants || this.isBusySubmit;
    },
    participantErrors(): TranslateResult[] {
      return []; // TODO
    },
    messageErrors(): TranslateResult[] {
      return []; // TODO
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: CreateConversationRequest = {
        participant: _.toInteger(this.form.participant),
        message: {
          content: _.toString(this.form.content),
        },
      };

      this.isBusySubmit = true;
      ConversationClient.createConversation(request)
        .then((conversation) => {
          this.$router.push({ name: "conversation", params: { conversationId: _.toString(conversation.id) } })
            .catch(() => {
              // navigation duplicated
              this.$emit("onSubmit");
            });
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    fetchParticipants(): void {
      this.isBusyParticipants = true;
      ParticipantClient.getParticipants(new AllItems())
        .then((response) => {
          this.participants = response.items;
        })
        .finally(() => {
          this.isBusyParticipants = false;
        });
    },
  },
  created(): void {
    this.fetchParticipants();
  },
});
