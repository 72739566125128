

























































































import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { TranslateResult } from "vue-i18n";
import { AllItems } from "@/api/AbstractClient";
import SchoolClient from "@/api/school/SchoolClient";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import moment from "moment";
import UserClient from "@/api/user/UserClient";
import { UpdateSettingRequest } from "@/api/user/types/Requests";

export default Vue.extend({
  name: "SchoolAndSchoolYearForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusySchools: false,
    isBusySchoolYears: false,
    // enums
    schools: [] as SchoolResponse[],
    schoolYears: [] as SchoolYearResponse[],
    // form
    form: {
      school: null as number | null,
      school_year: null as number | null,
    },
  }),
  validations: {
    form: {
      school: { required },
      school_year: { required },
    },
  },
  watch: {
    "form.school": function(schoolId?: number) {
      if (schoolId) {
        this.fetchSchoolYears(schoolId);
      } else {
        this.schoolYears = [];
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusySchools;
    },
    schoolErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.school?.$dirty) return errors;
      !this.$v.form.school.required && errors.push(this.$t("layout.validation.school.required"));
      return errors;
    },
    schoolYearErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.school_year?.$dirty) return errors;
      !this.$v.form.school_year.required && errors.push(this.$t("layout.validation.school_year.required"));
      return errors;
    },
  },
  methods: {
    fetchSchools(): void {
      this.isBusySchools = true;
      SchoolClient.getSchools(new AllItems())
        .then((response) => {
          this.schools = response.items;
        })
        .finally(() => {
          this.isBusySchools = false;
        });
    },
    fetchSchoolYears(schoolId: number): void {
      this.isBusySchoolYears = true;
      SchoolYearClient.getSchoolYears(schoolId, new AllItems())
        .then((response) => {
          this.schoolYears = response.items;
        })
        .finally(() => {
          this.isBusySchoolYears = false;
        });
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const school = this.schools.find((school: SchoolResponse): boolean => school.id === this.form.school);
      const schoolYear = this.schoolYears.find((schoolYear: SchoolYearResponse): boolean => schoolYear.id === this.form.school_year);

      if (!school || !schoolYear) {
        return; // should not happen
      }

      const request: UpdateSettingRequest = {
        selected_school: school.id,
        selected_school_year: schoolYear.id,
      };

      this.isBusySubmit = true;
      UserClient.updateUserSetting(request)
        .then((response) => {
          this.$store.commit("Setting/setSelectedSchool", response.selected_school);
          this.$store.commit("Setting/setSelectedSchoolYear", response.selected_school_year);
          this.$emit("onSubmit");
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    initDefaults(): void {
      const school = this.$store.getters["Setting/getSelectedSchool"] as SchoolResponse | null;
      if (school !== null) {
        this.form.school = school.id;
        this.fetchSchoolYears(school.id);
      }

      const schoolYear = this.$store.getters["Setting/getSelectedSchoolYear"] as SchoolYearResponse | null;
      if (schoolYear !== null) {
        this.form.school_year = schoolYear.id;
      }
    },
    isCurrentSchoolYear(schoolYear: SchoolYearResponse): boolean {
      return moment().isBetween(schoolYear.date_from, schoolYear.date_to, "days", "[]");
    },
  },
  created(): void {
    this.initDefaults();
    this.fetchSchools();
  },
});
