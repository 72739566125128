



























import Vue from "vue";
import NotificationsList from "@/components/notifications/misc/NotificationsList.vue";
import NotificationClient from "@/api/notification/NotificationClient";

export default Vue.extend({
  name: "Notifications",
  components: { NotificationsList },
  data: () => ({
    // loading
    isBusyUnreadNotificationsCount: false,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyUnreadNotificationsCount;
    },
    unreadNotificationsCount(): number {
      return this.$store.getters["Application/getUnreadNotificationsCount"];
    },
  },
  methods: {
    onMarkAsRead(): void {
      if (this.unreadNotificationsCount > 0) {
        const count = this.unreadNotificationsCount -= 1;
        this.$store.commit("Application/setUnreadNotificationsCount", count);
      }
    },
    onMarkAsUnread(): void {
      const count = this.unreadNotificationsCount += 1;
      this.$store.commit("Application/setUnreadNotificationsCount", count);
    },
    onMarkAllAsRead(): void {
      this.$store.commit("Application/setUnreadNotificationsCount", 0);
    },
    fetchUnreadNotificationsCount(): void {
      this.isBusyUnreadNotificationsCount = true;
      NotificationClient.getUnreadNotificationsCount()
        .then((response) => {
          this.$store.commit("Application/setUnreadNotificationsCount", response.unread_notifications_count);
        })
        .finally(() => {
          this.isBusyUnreadNotificationsCount = false;
        });
    },
  },
});
