



































import Vue from "vue";
import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import SchoolAndSchoolYearForm from "@/layouts/form/SchoolAndSchoolYearForm.vue";

export default Vue.extend({
  name: "SchoolAndSchoolYearSetting",
  components: { SchoolAndSchoolYearForm },
  data: () => ({
    // loading
    isBusySubmit: false,
    // dialog
    dialog: false,
  }),
  computed: {
    school(): SchoolResponse | null {
      return this.$store.getters["Setting/getSelectedSchool"];
    },
    schoolYear(): SchoolYearResponse | null {
      return this.$store.getters["Setting/getSelectedSchoolYear"];
    },
  },
  methods: {
    onSubmit(): void {
      this.dialog = false;
    },
  },
  created(): void {
    if (this.$route.name === "dashboard" && (this.school === null || this.schoolYear === null)) {
      this.dialog = true;
    }
  },
});
