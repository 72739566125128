import { SchoolResponse } from "@/api/school/types/Responses";
import { PaginatedResponse } from "@/api/common/types/Responses";
import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { CreateSchoolRequest, UpdateSchoolRequest } from "@/api/school/types/Requests";
import { AxiosResponse } from "axios";

export default class SchoolClient extends AbstractClient {
  /**
   * Perform get my schools request
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getSchools(pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<SchoolResponse>> {
    return AbstractClient.get("/api/schools", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(SchoolResponse, response.data));
  }

  /**
   * Perform get all school request
   * @param pagination pagination/filters/sort configuration
   */
  static getAllSchools(pagination: FilterQueryParameters): Promise<PaginatedResponse<SchoolResponse>> {
    return AbstractClient.get("/api/schools/all", pagination)
      .then((response) => PaginatedResponse.fromResponseData(SchoolResponse, response.data));
  }

  /**
   * Perform get school request
   * @param schoolId school ID
   */
  static getSchool(schoolId: number): Promise<SchoolResponse> {
    return AbstractClient.get("/api/schools/" + schoolId)
      .then((response) => new SchoolResponse(response.data));
  }

  /**
   * Perform create school request
   * @param request create school request
   */
  static createSchool(request: CreateSchoolRequest): Promise<SchoolResponse> {
    return AbstractClient.post("/api/schools", request)
      .then((response) => new SchoolResponse(response.data));
  }

  /**
   * Perform update school request
   * @param request update school request
   */
  static updateSchool(schoolId: number, request: UpdateSchoolRequest): Promise<SchoolResponse> {
    return AbstractClient.patch("/api/schools/" + schoolId, request)
      .then((response) => new SchoolResponse(response.data));
  }

  /**
   * Perform delete school request
   * @param schoolId school ID
   */
  static deleteSchool(schoolId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/schools/" + schoolId);
  }
}
