































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "PageSubtitle",
  props: {
    title: {
      type: String as PropType<string | null>,
    },
    noBottomMargin: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    show(): boolean {
      return this.title !== null;
    },
    dark(): boolean {
      return this.$vuetify.theme.dark;
    },
  },
});
