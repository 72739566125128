






























import UserClient from "@/api/user/UserClient";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "ReplaceAvatarForm",
  mixins: [validationMixin],
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyUser: false,
    // form
    form: {
      avatar: "" as Blob | string,
    },
  }),
  validations: {
    form: {
      avatar: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyUser;
    },
    avatarErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.avatar?.$dirty) return errors;
      !this.$v.form.avatar.required && errors.push(this.$t("users.validation.avatar.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const formData = new FormData();
      formData.append("avatar", this.form.avatar);

      this.isBusySubmit = true;
      UserClient.replaceMyAvatar(formData)
        .then(() => {
          this.isBusyUser = true;
          UserClient.getMyProfile()
            .then((response) => {
              this.$store.commit("User/setAvatarUrl", response.avatar_url);
              this.$snackbarSuccess(this.$t("users.setting.setting_updated"));
              this.$emit("onSubmit");
            })
            .finally(() => {
              this.isBusyUser = false;
            });
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
