







































































import ExportClient from "@/api/export/ExportClient";
import { ExportColumnRequest, ExportRequest } from "@/api/export/types/Requests";
import { DataTableHeader } from "@/components/data-table/DataTable.vue";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import ExportContentType from "@/enums/ExportContentType";
import { saveAs } from "file-saver";
import _ from "lodash";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "DataTableExport",
  components: { HelpMessage },
  mixins: [validationMixin],
  props: {
    exportPath: {
      type: String,
      required: true,
    },
    exportableColumns: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyExport: false,
    // from data
    form: {
      file_name: "" as string,
      content_type: ExportContentType.XLSX as string,
      columns: [] as DataTableHeader[],
    },
  }),
  validations: {
    form: {
      file_name: { required },
      content_type: { required },
      columns: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyExport;
    },
    contentTypes(): any[] {
      return [
        {
          text: "XLSX",
          value: ExportContentType.XLSX,
        },
        {
          text: "ODS",
          value: ExportContentType.ODS,
        },
        {
          text: "CSV",
          value: ExportContentType.CSV,
        },
      ];
    },
    fileNameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.file_name?.$dirty) return errors;
      !this.$v.form.file_name.required && errors.push(this.$t("data_table.validation.file_name.required"));
      return errors;
    },
    contentTypeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.content_type?.$dirty) return errors;
      !this.$v.form.content_type.required && errors.push(this.$t("data_table.validation.content_type.required"));
      return errors;
    },
    columnsErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.columns?.$dirty) return errors;
      !this.$v.form.columns.required && errors.push(this.$t("data_table.validation.columns.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: ExportRequest = {
        path: this.exportPath,
        file_name: this.form.file_name,
        columns: this.form.columns.map((column: DataTableHeader): ExportColumnRequest => ({
          name: this.exportableColumnName(column),
          json_paths: column.export?.json_paths ?? [],
          delimiter: column.export?.delimiter,
        })),
      };

      this.isBusyExport = true;
      ExportClient.export(request, this.form.content_type)
        .then((response) => {
          const filename = response.headers["content-disposition"]
            .split(";")
            .find((n: string[]) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          const url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename);
          this.$emit("onSubmit");
        })
        .finally(() => {
          this.isBusyExport = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    exportableColumnName(column: DataTableHeader): string {
      return _.toString(column?.export?.name ?? column.text);
    },
    initDefaults(): void {
      this.exportableColumns.forEach((column: DataTableHeader): void => {
        this.form.columns.push(column);
      });
    },
  },
  created(): void {
    this.initDefaults();
  },
});
