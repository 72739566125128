












import Vue from "vue";

export default Vue.extend({
  name: "HelpMessage",
  props: {
    dismissible: {
      type: Boolean,
      default: () => false,
    },
    prominent: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => "info",
    },
    noMargin: {
      type: Boolean,
      default: () => false,
    },
  },
});
