
































import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import SchoolClassAndSchoolYearForm from "@/components/layout/form/SchoolClassAndSchoolYearForm.vue";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import UserClient from "@/api/user/UserClient";
import { ChildToSchoolClassSettingRequest, UpdateSettingRequest } from "@/api/user/types/Requests";
import { ChildToSchoolClassState } from "@/store/modules/Family.store";

export default Vue.extend({
  name: "SelectChildItem",
  components: { SchoolClassAndSchoolYearForm },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    // dialogs
    selectSchoolClassAndSchoolYearDialog: false,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    persistent(): boolean {
      return this.$store.getters["Family/getChildToSchoolClass"](this.child) === null;
    },
    childSchoolClassName(): string {
      const childToSchoolClass = this.$store.getters["Family/getChildToSchoolClass"](this.child);
      return childToSchoolClass !== null ? childToSchoolClass.schoolClass.name : null;
    },
    childSchoolYearName(): string {
      const childToSchoolClass = this.$store.getters["Family/getChildToSchoolClass"](this.child);
      return childToSchoolClass !== null ? childToSchoolClass.schoolClass.schoolYear.name : null;
    },
  },
  methods: {
    onSelectSchoolClassAndSchoolYear(schoolClass: SchoolClassResponse): void {
      this.$store.commit("Family/setSelectedChild", { selectedChild: this.child });
      this.$store.commit("Family/setChildToSchoolClass", {
        child: this.child,
        schoolClass: schoolClass,
      });

      // prepare update setting request
      let childToSchoolClassSettings = this.$store.getters["Family/getChildrenToSchoolClasses"]
        .filter((childToSchoolClass: ChildToSchoolClassState): boolean => childToSchoolClass.child.id !== this.child.id) // skip setting of current child, is defined below
        .map((childToSchoolClass: ChildToSchoolClassState): ChildToSchoolClassSettingRequest => ({
            child: childToSchoolClass.child.id,
            school_class: childToSchoolClass.schoolClass.id,
          }),
        );
      childToSchoolClassSettings.push({
        child: this.child.id,
        school_class: schoolClass.id,
      });
      const request: UpdateSettingRequest = {
        selected_child: this.child.id,
        child_to_school_class_settings: childToSchoolClassSettings,
      };

      this.updateSetting(request);
    },
    onClickChild(): void {
      const childToSchoolCLass = this.$store.getters["Family/getChildToSchoolClass"](this.child);
      if (childToSchoolCLass === null) {
        this.selectSchoolClassAndSchoolYearDialog = true;
      } else {
        this.$store.commit("Family/setSelectedChild", { selectedChild: this.child });
        // prepare update setting request
        const request: UpdateSettingRequest = {
          selected_child: this.child.id,
        };
        this.updateSetting(request);
      }
    },
    onClickSetting(): void {
      this.selectSchoolClassAndSchoolYearDialog = true;
    },
    updateSetting(request: UpdateSettingRequest): void {
      this.isBusySubmit = false;
      UserClient.updateUserSetting(request)
        .then(() => {
          window.location.reload();
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
